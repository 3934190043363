/* eslint-disable prettier/prettier */
<template>
  <div>
    <el-table :data="rows">
      <el-table-column label="ID" prop="id" width="50"></el-table-column>
      <el-table-column label="标题" prop="name" width="80"></el-table-column>
      <el-table-column label="路径" prop="url" width="400"></el-table-column>
      <el-table-column label="创建时间" prop="createTime"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="assId(scope.row.id)">
            <el-upload
              class="avatar-uploader"
              :action="getUrl()"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              >上传文件{{ scope.row.id }}</el-upload
            >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { downloadHost } from "@/environment";

export default {
  name: "profile-picture",
  data() {
    return {
      rows: [],
      id: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions("profilePicture", ["list", "edit"]),
    getUrl() {
      return process.env.VUE_APP_BASE_API + "/file/upload";
    },
    load() {
      this.list({}).then(res => {
        this.rows = res.data.data;
      });
    },
    assId(id) {
      this.id = id;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = downloadHost + res.data;
      console.log("imageUrl===", res);
      //修改url
      this.edit({ id: this.id, url: downloadHost + res.data }).then(res => {
        if (res.data.code === 2000) {
          this.$message.success("修改成功！");
          this.load();
        }
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
